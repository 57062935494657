// FORMS & INPUTS
@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

// ELEMENTS
@mixin alpha-attribute($attribute, $color, $background) {
    $percent: alpha($color) * 100%;
    $opaque: opacify($color, 1);
    $solid-color: mix($opaque, $background, $percent);
    #{$attribute}: $solid-color;
    #{$attribute}: $color;
}

@mixin opacity($opacity: 1) {
    $IEOpacity: $opacity*100;
    filter: alpha(opacity=$IEOpacity);
    -ms-filter: alpha(opacity=$IEOpacity);
    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$IEOpacity});
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$IEOpacity});
    opacity: $opacity;
}

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

// FLEXBOX
@mixin flex-column {
    display: flex;
    flex-direction: column;
}
  
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
  
@mixin flex-center-column {
    @include flex-center;
    flex-direction: column;
}
  
@mixin flex-center-vert {
    display: flex;
    align-items: center;
}
  
@mixin flex-center-horiz {
    display: flex;
    justify-content: center;
}

// Cover Background
@mixin cover-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
}

@mixin cover-background-top {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 25%;
}


// FONTS
@mixin antialias {
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin appearance($val: none) {
	-webkit-appearance: $val;
	-moz-appearance: $val;
	appearance: $val;
}

@mixin vertical-align($value) {
    position: relative;
    top: $value;
    transform: translateY(-$value);
}




//MEDIA QUERIES
$smartphone: 640;
$tablet: 768;
$large: 992;
$desktop: 1200;
$desktop-xl: 1441; 
$desktop-xxl: 1680;
$desktop-xxxl: 1920;

@mixin smartphone {
    @media only screen and (min-width: $smartphone  * 1px) {
      @content;
    }
}

@mixin max-smartphone {
    @media only screen and (max-width: $smartphone * 1px) {
      @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: $tablet * 1px) {
      @content;
    }
}

@mixin max-tablet {
    @media only screen and (max-width: $tablet * 1px) {
      @content;
    }
}
   
@mixin large {
    @media only screen and (min-width: $large * 1px) {
        @content;
    }
}

@mixin max-large {
    @media only screen and (max-width: $large * 1px) {
        @content;
    }
}
  
@mixin desktop {
    @media only screen and (min-width: $desktop * 1px) {
        @content;
    }
}

@mixin desktop-xl {
    @media only screen and (min-width: $desktop-xl * 1px) {
        @content;
    }
}

@mixin desktop-xxl {
    @media only screen and (min-width: $desktop-xxl * 1px) {
        @content;
    }
}

@mixin desktop-xxxl {
    @media only screen and (min-width: $desktop-xxxl * 1px) {
        @content;
    }
}

  
//HORIZONTAL - VERTICAL CENTER
@mixin center {
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

@mixin center-top {
    left: 50%;
    top: 40%;
    -ms-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

@mixin transition {
    transition: all .5s;
}


 
  