@import "../src/scss/themes/theme-dark.scss";
@import "../src/scss/themes/theme-light.scss";
@import "../src/scss/_mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap");

 $primary-color: #19647E;
// $primary-color: #e9d5a1;
 $secondary-color: #19647E;
// $secondary-color: #efe1bd;
 $tertiary-color: #19647E;
// $tertiary-color: #ae944f;
 $badge-color: #CCEAF5;
// $badge-color: #f9f5e9;
 $switch-color: #1E7999;
// $switch-color: #353535;

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-title {
  padding-top: 5%;
  padding-bottom: 5%;
  font: 22px/24px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: black;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .section-title {
    padding-top: 10%;
  }
}

// -------------------------- Header --------------------------
.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-container {
  height: 50px;
}

.title-styles {
  font-family: "Raleway", sans-serif;
  font-size: 300%;
  @media (max-width: 480px) {
    font-size: 250%;
  }
}

.header-icon {
  height: 150px;
  width: 150px;
}

header {
  position: relative;
  height: 520px;
  min-height: 450px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  text-align: center;
  overflow: hidden;
  background-color: $primary-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 0;
}

.language-icon {
  font-size: 50px;
  cursor: pointer;
}

.language {
  font-size: 25px;
  background-color: $primary-color;
  padding: 50px 0px;
  // padding-bottom: 46px;
  // padding-top: 46px;
  // top: -5px;      // Compensate some bugs on mobile where space between here and header is visible
}

header h1 {
  font-size: 450%;
  text-align: center;
  font-weight: 600 !important;
  color: #353239;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  margin: 0 auto 18px auto;
  width: 100%;
  @media (max-width: 480px) {
    font-size: 400%;
  }
}

div.react-switch-bg {
  // background: $switch-color !important;
}
// --------------------------------- About ---------------------------------
.wave {
  font-size: 160%;
}

#about {
  background: #fff;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  #about h1 {
    margin-bottom: -35px;
  }
}

.polaroid img {
  display: block;
  width: 200px;
  transition: .5s all ease;

  @include desktop {
    width: 300px;
  }
}

.polaroid span {
  background: #ffffff;
  display: inline-block;
  margin: 55px 75px 30px;
  padding: 15px 15px 30px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 0;
  position: relative;
}

.polaroid-icon {
  font-size: 400%;
  margin: 9% 5% 0 5%;
}

.font-trebuchet {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.card-body {
  height: auto;
  font-size: 1.4em;
  // line-height: 1.2em;

  @include desktop {
    font-size: 1.6em;
    // line-height: 200%;
  }
}


// ---------------------------- Projects ----------------------------
.foto img {
  object-fit: cover;
  height: 300px;

  display: block;
  width: 100%;
  max-width: 300px;
  transition: .5s all ease;

  @include smartphone {
    max-width: 400px;
  }

  @include tablet {
    width: 100%;
  }

  @include desktop-xl {
    min-width: 400px;
  }

}

.foto div {
  background: #ffffff;
  display: inline-block;
  margin: 0 auto 5% auto;
  padding: 10px 10px 5px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 0;
  position: relative;
}

.foto div:after {
  color: #333;
  font-size: 25px;
  content: attr(title);
  position: relative;
  top: 15px;
}

.foto div:hover {
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  transform: scale(1.01);
  z-index: 10;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
}

.project-title-settings {
  margin-top: 5%;
  font: 12px/18px "opensans-bold", sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: black;
  text-align: center;
  transition: .5s all ease;

  @include smartphone {
    font-size: 15px;
    line-height: 24px;
  }

  @include desktop {
    font-size: 18px;
  }
}

.project-date {
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 8px 15px 2px 15px;
  position: relative;
  z-index: -500;
  border-top: 5px solid #696969;
  border-radius: 0 0 5px 5px;
  background-color: #696969;
  color: white;
}

#portfolio {
  background: $secondary-color;
  // padding-bottom: 5%;
}

.portfolio-item {
  max-width: 100%;
  margin-bottom: 15px;
  text-align: center;
}

.portfolio .portfolio-item .portfolio-item-caption {
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
  background-color: rgba(51, 51, 51, 0.9);
}

.portfolio .portfolio-item .portfolio-item-caption:hover {
  opacity: 1;
}

.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  .portfolio .closeButtonResponsive {
    display: block;
  }
  .portfolio .portfolio-item {
    margin-bottom: 30px;
  }
}

#expend {
  position: relative;
  left: 50%;
  transform: translate(-50%, 50%);
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: white;
  border: 1px solid $primary-color;
  color: black;
  font-size: 18px;
  z-index: 1;

  &:focus {
    outline: none;
  }
}

.hiddenProjects {
  overflow: hidden;
  transition: .8s max-height;
}

// ----------------------- ProjectDetailsModals --------------------------
.link-href {
  color: black;
}

.slider-image {
  border: 5px solid #d7caaa;
}

.slider-tab {
  background-color: #d7caaa;
  height: 25px;
}

.slider-iconfiy {
  margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
  cursor: none !important;
  display: none;
}

.modal-inside .modal-content {
  background: white;
}

.modal-close {
  text-align: right;
  padding: 10px 15px 10px 15px;
  cursor: pointer;
}

.close-icon {
  color: black;
  font-weight: lighter !important;
}

.modal-description {
  text-align: justify;
  padding: 5px 5px 0 5px;
  margin-bottom: 20px;
  font-size: 12px;
}


// ---------------------------- Skills ----------------------------
#skills {
  // background: #1f1f1f;
  background: white;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 2%;
  margin: auto;
}

.languages {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly;
  padding: 10px !important;
}

.skillsCard div {
  padding: 1px;
}

.CircularProgressbar-path {
  stroke: $tertiary-color !important;
}

.CircularProgressbar-text {
  fill: black !important;
}

.progress-bar {
  background-color: $primary-color !important;
}

#skillWeb3 {
  @media screen and (min-width: 992px) and (max-width: 1023px) {
    top: -13px;
  }
  @media screen and (min-width: 1023px) {
    top: -26px;
  }
}

#skillLanguages {
  @media screen and (min-width: 992px) {
    top: -48px;
  }
}

// ---------------------------- Publications ----------------------------

#publications {
  background: $secondary-color;
  padding-bottom: 4%;

  .section-title {
    @media only screen and (min-width: 1170px) {
      padding-bottom: 3%; /* Reduce padding below title for desktop */
    }
  }

  a {
    color: inherit; /* Use the color inherited from the parent */
    text-decoration: none; /* Remove underline */
  }

  .card {
    font-size: 1.2em;;
    width: 100%;
    margin: 0 auto; /* Center the card */
    margin-bottom: 2em;
  }
  
  .card-subtitle {
    font-size: 0.7em; /* Smaller font size for the publication date */
  }

  .publication-journal {
    font-size: 0.8em; /* Smaller font size for the journal name */
    padding-top: 0.5em; /* Add some space between the journal name and the publication date */
    font-weight: normal;
  }
}


// ---------------------------- Experience ----------------------------

#resume {
  background: $secondary-color;
  padding-bottom: 4%;
}

.experience-icon {
  font-size: 300%;
  margin-top: 25%;
  text-align: center;
}

.experience-icon-devicon {
  position: relative;
  top: 15px;
}

.experience-icon-fa {
  position: relative;
  top: 2px;
}

@media only screen and (max-width: 1200px) {
  .experience-icon-devicon {
    position: relative;
    top: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .experience-icon-devicon {
    position: relative;
    top: 12px;
  }
}

.main-badge {
  font-size: 13px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: $tertiary-color !important;
  color: white;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.experience-badge {
  font-size: 11px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: $badge-color !important;
  color: black;
  font-weight: lighter !important;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

span.vertical-timeline-element-icon {
  background: $tertiary-color !important;
}

.vertical-timeline-element-date {
  padding: 0 !important;
  margin: 10px !important;
  font-size: 13px !important;
  color: black;
  opacity: 0.9 !important;

  @media only screen and (min-width: 1170px) {
    color: white;
  }
}

//Prevent react-vertical-timeline-component jump on mobile
html, body {
  overflow-x: hidden;
}

@keyframes timeline-slide {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  60% {
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 1170px) {
  .experience-icon {
    font-size: 170%;
    margin-top: 27%;
    text-align: center;
  }
}

.skills {
  margin-top: 36px;
  list-style: none;
}

.awssld__content {
  background-color: white !important;
  img {
    object-fit: contain !important;
  }
}

.awssld__next {
  outline: none !important;
}

.awssld__prev {
  outline: none !important;
}

.loader-bar-color {
  color: black !important;
}





// ---------------------------- Footer ------------------------
footer {
  background: #1f1f1f;
  min-height: 100px;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  color: white;
  position: relative;
  text-align: center;
}

footer a, footer a:visited {
  color: #fff;
}

footer a:hover, footer a:focus {
  color: #fff;
}

.social-links {
  margin-top: 50px;
  font-size: 22px;
}

.skillsCard {
  background-color: #f7f7f7 !important;
  padding: 20px 10px 20px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  // box-shadow: 1px 1px 1px 1px #f7f7f7;
  @media only screen and (max-width: 768px) {
    top: 0px !important; 
  }
}




// ------------------------------ Other ------------------------------


// .bars {
//   width: 95%;
//   float: left;
//   padding: 0;
//   text-align: left;
// }

// .bars .skills {
//   margin-top: 36px;
//   list-style: none;
// }

// .bars li {
//   position: relative;
//   margin-bottom: 60px;
//   background: #ccc;
//   height: 42px;
//   border-radius: 3px;
// }

// .bars li em {
//   font: 15px "opensans-bold", sans-serif;
//   color: #313131;
//   text-transform: uppercase;
//   letter-spacing: 2px;
//   font-weight: normal;
//   position: relative;
//   top: -36px;
// }

// .bar-expand {
//   position: absolute;
//   left: 0;
//   top: 0;
//   margin: 0;
//   padding-right: 24px;
//   background: #313131;
//   display: inline-block;
//   height: 42px;
//   line-height: 42px;
//   border-radius: 3px 0 0 3px;
// }

// .skill-icon {
//   font-size: 180%;
//   color: white;
//   text-align: center;
//   position: relative;
// }

// .skills-tile {
//   background-color: transparentize(#f8f5f4, 0.95);
//   padding: 10px 10px 5px 10px;
//   width: 100px;
//   margin: 5px 0 5px 0;
//   border-radius: 8px;
// }
